$grid-gutter-width: 30px;
$menuWidth: 290px;

$grid-breakpoints: (
  xs: 0,
  sm: 576px + $menuWidth,
  md: 767px + $menuWidth,
  lg: 992px + $menuWidth,
  xl: 1200px + $menuWidth
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
) !default;

$theme-colors: (
  "primary": #5972D9,
  "secondary": #D2E5E8,
  "warning": #F6AE2D,
  "danger": #EB3636,
  "success": #4FAD76,
  "info": #2D286F
);

@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/root";
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/buttons";
@import "node_modules/bootstrap/scss/button-group";

.Toastify__toast {
  border-radius: 10px;
  
  &--error {
    background: #EB3635;
  }

  &--success {
    background: #70A9A1;
  }
}

.container-fluid {
  padding-left: 56px;
  padding-right: 56px;
}